import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Home from "../pages/home/Home";
import Gadgets from "../pages/home/components/Gadgets";

const AllGadget = () => {
  return (
    <div>
      <Gadgets />
      <Footer />
    </div>
  );
};

export default AllGadget;
