import React from "react";
import PageWrapper from "../../../components/PageWrapper";
import CustomButton from "../../../components/CustomButton";

const HeroBadge = () => {
  return (
    <PageWrapper title="Big Sale">
      <div className="py-10 md:py-20 w-full overflow-hidden relative bg-[#585B62] rounded-lg">
        <div className="absolute -left-4 -top-4">
          <img src="/images/hero-3.png" className="w-[45%]" alt="" />
        </div>
        <div className="absolute right-0 top-[70%] md:-top-4">
          <img src="/images/hero-5.png" className="w-32 lg:w-96" alt="" />
        </div>
        <div className="flex-center relative px-4 flex-col gap-2">
          <p className="font-sans text-lg text-white">Save 30% Shop</p>
          <p className="text-font text-white text-xl md:text-3xl font-sans font-semibold">
            In this season, find the best 🔥
          </p>
          <CustomButton
            title="Show More"
            bgColor="bg-[#49B533]"
            className="py-3 w-full text-white mt-3 rounded-lg md:w-32"
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default HeroBadge;
