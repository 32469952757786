import React from "react";
// import PageWrapper from "./PageWrapper";
import { footItems, navlinks, paymentCard } from "../constants";
import { Clock, MapPin, Phone, Send } from "lucide-react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="w-full pt-20 text-white bg-base-color">
      <div className="page-wrapper">
        <div className="mb-10 grid grid-cols-3 gap-10">
          <div className="col-span-4 md:col-span-2 lg:col-span-1">
            <div className="w-12 h-12 rounded-full bg-black">
              <img src="/logo.png" alt="logo" />{" "}
            </div>
            {/* <p className="text-3xl font-semibold text-[#ffb641]">
              Bukyz Empire
            </p> */}
            <p className="mt-5 text-sm">
              Where to get phones, laptops, home appliances, with accesories and
              all other home gadgets.
            </p>
            <div className="mt-6 text-sm">
              <div className="flex-gap gap-3">
                <MapPin />
                <div>
                  <p>37A St Michael's Rd, Aba, 450001, Abia State.</p>
                  <p>C39 ebubeagu line,umuaka main pack, Imo State.</p>
                </div>
              </div>
              <div className="flex-gap my-5 gap-3">
                <Clock />
                <p>
                  8:00am - 6:00pm, <em>mon - sat</em>
                </p>
              </div>
              <div className="flex-gap gap-3">
                <Phone />
                <p> 0818 360 5544,</p>
                <p> 0805 561 1089</p>
              </div>
            </div>
          </div>
          {/* <div className="col-span-2 md:col-span-2 lg:col-span-1"> */}
          {/* <p className="font-semibold">OTHER PAGES</p>
            <div className="w-16 h-[2px] bg-white mt-1"></div>
            <div className="mt-6">
              {navlinks.map((item, index) => {
                return (
                  <div className="my-3 text-sm" key={index}>
                    {item.title}
                  </div>
                );
              })}
            </div> */}
          {/* </div> */}
          <div className="flex justify-start lg:justify-center col-span-4 md:col-span-2 lg:col-span-1">
            <div>
              <p className="font-semibold">Social Media</p>
              <div className="w-16 h-[2px] bg-white mt-1"></div>
              <div className="mt-6">
                {footItems.map((item, index) => {
                  return (
                    <a key={index} href={item.link}>
                      <div className="my-3 text-sm">{item.name}</div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-span-4 md:col-span-2 lg:col-span-1">
            <p className="font-semibold">CONTACT US</p>
            <div className="w-16 h-[2px] bg-white mt-1"></div>
            <p className="text-sm mt-6">For more enquiries please contact us</p>
            <div className="mt-6">
              <div className="relative">
                <div className="w-12 rounded-r-lg h-full bg-[#585B62] flex-center absolute right-0 top-0">
                  <Send />
                </div>
                <input
                  type="text"
                  className="w-full rounded-lg pl-6 outline-none py-3"
                  placeholder="Email Address"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-center gap-5 md:gap-0 border-t py-8 flex-col md:flex-row">
          <div className="flex-gap gap-4">
            {/* <p>Supported Payment :</p> */}
            <div className="flex-gap gap-3">
              {/* {paymentCard.map((item, index) => {
                return (
                  <div className="w-10 h-6 overflow-hidden" key={index}>
                    <img src={item} alt="" className="w-full h-full" />
                  </div>
                );
              })} */}
            </div>
          </div>
          <div className="flex-gap gap-3">
            <p>&#169; {currentYear} Bukyz Empire</p>
            {/* <p>Terms</p>
            <p>Help</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
