import React, { useState } from "react";
import { navImg, navlinks } from "../constants";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import hero from "./img/nav.png";

const Navbar = () => {
  const [mobile, setMobile] = useState(false);
  const [state, setState] = useState("");
  const toggle = () => {
    setMobile(!mobile);
    if (mobile) {
      setState("true");
    } else {
      setState("false");
    }
  };
  const { user } = useAuthContext();
  return (
    <div className="sticky top-0 z-50 ">
      {user && <SideBar mobile={mobile} state={state} setMobile={setMobile} />}
      <div
        className="w-full py-3 relative z-30 bg-white shadow-lg"
        style={{
          backgroundImage: `url(${hero})`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "auto",
          // width: "100%",
        }}
      >
        <div className="max-w padding-x flex-between">
          <div className="flex-gap gap-4">
            <div className="w-12 h-12 rounded-full bg-black">
              <img src="/logo.png" alt="logo" />{" "}
            </div>
            <p className="text-2xl text-font font-semibold">Bukyz Empire</p>
          </div>
          {user && (
            <div className="flex-gap  gap-5 xl:gap-9">
              {navlinks.map((item, index) => {
                return (
                  <Link
                    to={item.link}
                    key={index}
                    className="text-font hidden lg:block"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          )}
          <div className="flex-gap gap-7">
            {!user &&
              navImg.map((item, index) => {
                return (
                  <img src={item} key={index} alt="nav-icon" className="w-6" />
                );
              })}
            <div onClick={toggle} className="">
              {!mobile ? (
                <img
                  src="/icons/menu.png"
                  alt="nav-icon"
                  className="w-6 block lg:hidden"
                />
              ) : (
                <img
                  src="/icons/x.png"
                  alt="nav-icon"
                  className="w-8 h-6 block lg:hidden"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
