import { navLinksProps } from "../types";

export const navlinks: navLinksProps[] = [
  { title: "Home", link: "/" },
  { title: "Add Gadget", link: "/dashboard/add-gadget" },
  { title: "Gadgets", link: "/dashboard/home" },
  // { title: "Service", link: "/service" },
  // { title: "Blog", link: "/blog" },
  // { title: "Gallery", link: "/gallery" },
  //   {
  //     title: "Testimonials",
  //     link: "/testimonials",
  //   },
  // { title: "Contact", link: "/contact" },
];

export const navImg = [
  // "/icons/user.png",
  "/icons/search.png",
  // "/icons/cart.png",
  //   "/icons/heart.png",
];

export const paymentCard = [
  "/images/master.png",
  "/images/paypal.png",
  "/images/visa.png",
];

export const footItems = [
  {
    name: "Facebook",
    link: "https://web.facebook.com/people/Bukyzempire-gadget/100078187506907/",
  },
  { name: "Twitter (X)", link: "https://twitter.com/mrbukykiaz?lang=en" },
  { name: "TikTok", link: "https://www.tiktok.com/@bukyzempire" },
  // { name: "Sotwe", link: "https://www.sotwe.com/mrbukykiaz" },
  { name: "Instagram", link: "https://www.instagram.com/bukyzempire/" },
];
