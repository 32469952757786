import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import bukyzServices from "../../../hooks/bukyzServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddGadget = () => {
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any>([]);
  const [desc, setDesc] = useState<string | null>();
  const [name, setName] = useState<string | null>();
  const [category, setCategory] = useState<string | null>();
  const [price, setPrice] = useState<string | null>();

  let navigate = useNavigate();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    for (let i = 0; i < e.target.files!.length; i++) {
      const reader = new FileReader();
      const newImage: any = e.target.files?.[i];
      newImage["id"] = Math.random();
      setFile((prevState: any) => [...prevState, newImage]);
      // const img = [];
      // reader.onload = (e) => {
      setSelectedImage((prevState: any) => [
        ...prevState,
        URL.createObjectURL(newImage),
      ]);
      // };
    }

    if (selectedFile) {
      const reader = new FileReader();
      // const img = []
      // reader.onload = (e) => {
      //   setSelectedImage(e.target?.result as string);
      // };
      reader.readAsDataURL(selectedFile);
    } else {
      setSelectedImage([]);
    }
  };

  // console.log("file", selectedImage);
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!file || !desc || !name || !category || !price) {
      console.log("load");
      toast.error("Fill all fields before submitting");
    } else {
      setLoading(true);
      try {
        await bukyzServices.addGadget({
          file,
          desc,
          name,
          category,
          price,
        });
        setTimeout(() => {
          toast.success("Submitted Successfully");
          navigate("/dashboard/home");
          setLoading(false);
        }, 5000);
      } catch (error) {
        // console.log(error.message)
        toast.error("Error occured, try again later");
        setLoading(false);
      }
    }
  };
  return (
    <div className="flex-center">
      <div className="md:border w-full md:rounded-lg md:shadow-xl py-8 md:w-[850px] md:h-full px-4 mt-20">
        <p className="text-2xl font-semibold">Add Gadget</p>
        <form className="mt-10" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <div className="text-base font-normal text-gray-900">
              Upload image
            </div>
            <>
              <div className="opacity-30 group-hover:opacity-70 transition-opacity duration-500 ease-in-out mb-6">
                <input
                  type="file"
                  id="upload"
                  name="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </>
            {/* <label
              className="cursor-pointer group flex flex-col gap-2 items-center justify-center border border-dashed w-full h-[300px] rounded-lg"
              id="upload"
            > */}
            {selectedImage.length > 0 &&
              selectedImage.map((select, i) => {
                return (
                  <div className="m-3 flex flex-center">
                    <img
                      src={select}
                      alt="Selected"
                      className="w-full h-full rounded-lg object-cover"
                    />
                  </div>
                );
              })}

            {/* </label> */}
          </div>
          <div className="grid md:grid-cols-2 gap-5">
            <div className="">
              <p className="font-semibold">Name:</p>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                className="py-2 rounded-md mt-3 trans-props pl-3 w-full outline-none border focus:border-base-color"
              />
            </div>
            <div className="">
              <p className="font-semibold">Description:</p>
              <input
                type="text"
                onChange={(e) => setDesc(e.target.value)}
                className="py-2 rounded-md trans-props pl-3 mt-3 w-full outline-none border focus:border-base-color"
              />
            </div>
            <div className="">
              <p className="font-semibold">Category:</p>
              {/* <input
                type="text"
                onChange={(e) => setCategory(e.target.value)}
                className="py-2 rounded-md mt-3 trans-props pl-3 w-full outline-none border focus:border-base-color"
              /> */}
              <select
                name="Category"
                onChange={(e) => setCategory(e.target.value)}
                className="py-2 rounded-md trans-props pl-3 mt-3 w-full outline-none border focus:border-base-color"
              >
                <option value="none" selected disabled hidden>
                  Select an Option
                </option>
                <option value="smart">Smart Devices</option>
                <option value="home">Home Electronics</option>
                <option value="gaming">Gaming Gear</option>
              </select>
            </div>
            <div className="">
              <p className="font-semibold">Price:</p>
              <input
                type="number"
                onChange={(e) => setPrice(e.target.value)}
                className="py-2 rounded-md pl-3 trans-props mt-3 w-full outline-none border focus:border-base-color"
              />
            </div>
          </div>
          <div className="flex mt-5 justify-end">
            {!loading ? (
              <CustomButton className="py-3 w-full lg:w-40 text-white" />
            ) : (
              <CustomButton
                className="py-3 w-full lg:w-40 text-white"
                disabled
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGadget;
