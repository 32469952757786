import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Product from "../pages/product/Product";

const ProductDetails = () => {
  return (
    <div>
      <Navbar />
      <Product />
      <Footer />
    </div>
  );
};

export default ProductDetails;
