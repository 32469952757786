"use client";

// import { pageWrapperProps } from "@/app/types";
import { motion } from "framer-motion";
import { pageWrapperProps } from "../types";

const PageWrapper = ({ children, className, title }: pageWrapperProps) => {
  return (
    <motion.div
      transition={{
        ease: "easeInOut",
        duration: 0.8,
        delay: 0.8,
      }}
      viewport={{ once: true }}
      initial={{ opacity: 0 }}
      whileInView={{
        translateY: [80, 0],
        opacity: [0, 1],
      }}
      className={`${className} page-wrapper my-20`}
    >
      {title && (
        <div className="my-10 flex-center">
          <div className="flex-gap gap-3">
            <p className="font-semibold text-base-color text-2xl">{title}</p>
            <div className="h-[2px] w-12 mt-2 bg-base-color"></div>
          </div>
        </div>
      )}
      {children}
    </motion.div>
  );
};

export default PageWrapper;
