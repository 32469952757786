import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import { heroItems, heroWelCard } from "../constants";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Rating from "../../../components/Rating";
import CustomButton from "../../../components/CustomButton";
import { motion } from "framer-motion";
import { useCollection } from "../../../hooks/useCollection";
import { formatPrice } from "../../../components/format";
// import {} from ''

const Gadgets = () => {
  const [state, setState] = useState("Gaming Gear");
  const { documents } = useCollection("gadget");

  return (
    <PageWrapper title="All Products">
      <div className="w-full overflow-hidden md:border rounded-lg md:shadow-xl py-2 md:px-6">
        <div className="py-4 border-b relative flex-between flex-col w-full lg:flex-row">
          <div className="absolute -bottom-1 rounded-lg left-0 h-1 w-20 bg-base-color"></div>
          <p className="text-2xl font-semibold hidden lg:block">All Products</p>
        </div>
        <div className="my-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-10 rounded">
          {documents.map((item: any, index: number) => {
            return (
              <motion.div
                transition={{
                  ease: "easeInOut",
                  duration: 0.8,
                  delay: 0.4 * 0.4 + index,
                }}
                viewport={{ once: true }}
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  translateX: ["120%", "0%"],
                  opacity: 1,
                }}
                className="border hover:-translate-y-3 trans-props hover:shadow-xl pb-4 cursor-pointer group rounded-lg"
                key={index}
              >
                <div className="flex-center py-3 md:py-5">
                  <img
                    src={item?.file}
                    className="w-full md:w-[70%] group-hover:scale-110 trans-props"
                    alt=""
                  />
                </div>
                <div className="px-4">
                  <div className="w-12 text-xs flex-center py-1 bg-gray-200 rounded-full">
                    New
                  </div>
                  <p className="mt-3 font-semibold">{item.name}</p>
                  <p className="mt-1 text-sm">{item.desc}</p>
                  <div className="mt-3">
                    <Rating />
                  </div>
                  <div className="flex-gap mt-3">
                    {/* <CustomButton
                      title="Add to Cart"
                      className="py-1 opacity-0 -translate-x-[100%] group-hover:translate-x-0 group-hover:opacity-100"
                    /> */}
                    <div className="flex-gap gap-3">
                      <p className="font-semibold">{formatPrice(item.price)}</p>
                      {/* <s className="text-gray-500 text-sm">N {item.oldPrice}</s> */}
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Gadgets;
