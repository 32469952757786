import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import AddGadget from "../pages/dashboard/add-gadget/AddGadget";
import Navbar from "../components/Navbar";
import { useAuthContext } from "../hooks/useAuthContext";
import AllGadget from "./AllGadget";

const DashboardRoutes = () => {
  const { authIsReady, user } = useAuthContext();
  return (
    <div>
      <Navbar />
      {authIsReady && (
        <Routes>
          <Route
            path="/home"
            element={user ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route
            path="/add-gadget"
            element={user ? <AddGadget /> : <Navigate to="/" />}
          />
          {/* <Route
            path="/all-gadget"
            element={user ? <AllGadget /> : <Navigate to="/" />}
          /> */}
        </Routes>
      )}
    </div>
  );
};

export default DashboardRoutes;
