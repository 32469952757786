import { useParams } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";
import { useDocument } from "../../hooks/useDocument";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Product() {
  const { document } = useDocument("gadget");
  const { id } = useParams();
  const [file, setFile] = useState<any>([]);
  const [desc, setDesc] = useState<string | null>();
  const [name, setName] = useState<string | null>();
  const [category, setCategory] = useState<string | null>();
  const [price, setPrice] = useState<string | null>();

  // console.log("file", file);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // file.map((f: string) => {
  //   console.log("f", f);
  // });

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap: any = await document(id);
        // console.log(docSnap.data())
        setFile(docSnap.data().file);
        setDesc(docSnap.data().desc);
        setName(docSnap.data().name);
        setCategory(docSnap.data().category);
        setPrice(docSnap.data().price);
      } catch (error) {
        console.log(error);
      }
    };
    if ((id !== undefined || id !== "") && id !== ":id") {
      detail();
    }

    // eslint-disable-next-line
  }, [id]);
  return (
    <div className="flex-center mb-10 mt-10 bg-slate-200">
      <div className="w-full md:w-[650px] lg:w-[800px] px-4 md:rounded-xl p-10  md:shadow-xl md:border">
        <div className="h-full grid grid-cols-1 md:grid-cols-2 gap-4 content-center">
          <div className="bg-white rounded-lg">
            <Carousel
              showDots={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              infinite={true}
              customTransition="all .5"
              transitionDuration={500}
              responsive={responsive}
            >
              {file.map((f: any, i: number) => (
                <div className="" key={i}>
                  <img
                    src={f}
                    className="w-full md:w-[100%] group-hover:scale-110 trans-props rounded-lg"
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="gap-4 bg-white p-10 rounded-md">
            <p className="text-2xl mb-5 font-bold">{name}</p>
            <p className="text-4xl mb-5 font-bold">₦{price}</p>
            <p className="text-xl mb-5">{desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
