import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
// import Login from "./authentication/Login";
import HomePage from "./routes/HomePage";
import SignIn from "./authentication/SignIn";
import DashboardRoutes from "./routes/DashboardRoutes";

// Hooks
import { useAuthContext } from "./hooks/useAuthContext";
import AllGadget from "./routes/AllGadget";
import ProductDetails from "./routes/ProductDetails";

function App() {
  const { authIsReady, user } = useAuthContext();
  return (
    <>
      {authIsReady && (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/all-gadget" element={<AllGadget />} />
          <Route
            path="/login"
            element={!user ? <SignIn /> : <Navigate to="/" />}
          />
          <Route
            path="/dashboard/*"
            element={user ? <DashboardRoutes /> : <Navigate to="/" />}
          />
        </Routes>
      )}
    </>
  );
}

export default App;
