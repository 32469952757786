// import { db } from '../firebase'
import { appFirestore, appStorage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import {
  collection,
  // getDoc,
  addDoc,
  updateDoc,
  // deleteDoc,
  doc,
} from "firebase/firestore";

const bukyz = collection(appFirestore, "gadget");

class KingsooService {
  addGadget2 = (newGadget) => {
    return addDoc(bukyz, newGadget);
  };

  addGadget = async ({ file, name, desc, price, category }) => {
    const arr = [];
    file.map(async (f) => {
      const img = ref(appStorage, `${new Date()}${f.name}`);

      await uploadBytes(img, f).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          // const staffDoc = doc(bukyz);
          arr.push(url);
        });
      });
    });
    setTimeout(() => {
      addDoc(bukyz, {
        file: arr,
        name,
        desc,
        price,
        category,
      });
    }, 5000);

    // Promise.all(arr)
    //   .then(() =>
    //     addDoc(bukyz, {
    //       file: arr,
    //       name,
    //       desc,
    //       price,
    //       category,
    //     })
    //   )
    //   .catch((err) => alert(err));
  };
}

export default new KingsooService();
