import React from "react";
import { navlinks } from "../constants";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SideBar = ({ mobile, setMobile, state }: any) => {
  return (
    <div className="block overflow-hidden lg:hidden">
      <div
        className={`h-[100vh] trans-props fixed z-20 ${
          mobile ? "translate-x-0" : "translate-x-[120%]"
        } top-0 right-0 flex-center w-2/3 bg-white`}
      >
        <div className="flex flex-col gap-16">
          {navlinks.map((item, index) => {
            return (
              <Link
                to={item.link}
                onClick={() => setMobile(false)}
                className=""
                key={index}
              >
                <motion.div
                  transition={{
                    ease: "easeInOut",
                    duration: 0.4,
                    delay: 0.1 + 0.1 * index,
                  }}
                  animate={{
                    opacity: [0, 1],
                    translateY: [-100, 0],
                    translateX: [100, 0],
                  }}
                  key={state}
                  className=""
                >
                  {item.title}
                </motion.div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
