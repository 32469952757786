import { Pencil, Trash, X } from "lucide-react";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { heroItems } from "../home/constants";
import EditGadgets from "./components/EditGadgets";
import { useCollection } from "../../hooks/useCollection";
import { useFirestore } from "../../hooks/useFirestore";
import CustomButton from "../../components/CustomButton";

const DeleteModal = ({ closeModal1, ids }: any) => {
  const { deleteDocument } = useFirestore("gadget");
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
        <div
          className="absolute w-full h-full bg-black opacity-50"
          onClick={closeModal1}
        ></div>
        <div className="modal-container relative z-10 bg-white rounded-lg p-4">
          <div
            className="absolute top-0 right-0 mt-3 mr-3 text-xl cursor-pointer"
            onClick={closeModal1}
          >
            <X />
          </div>
          <p className="text-xl font-semibold">Are you sure</p>

          <div className="flex mt-5 justify-between gap-4">
            <CustomButton
              title="Cancel"
              handleEvent={closeModal1}
              className="py-3 w-full lg:w-40 text-white bg-black"
            />
            <CustomButton
              title="Delete"
              handleEvent={() => {
                deleteDocument(ids);
                closeModal1();
              }}
              className="py-3 w-full lg:w-40 text-white bg-red-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [showModal, setshowModal] = useState(false);
  const [show, setshow] = useState(false);
  const [modalItems, setmodalItems] = useState<any>();
  const { documents } = useCollection("gadget");
  const [ids, setIds] = useState<string>("");
  const { deleteDocument } = useFirestore("gadget");

  // console.log("documents", documents);

  const closeModal = () => {
    setshowModal(!showModal);
    return;
  };

  const closeModal1 = () => {
    setshow(!show);
    return;
  };

  const updateModalItems = (params: any) => {
    setshowModal(true);
    setmodalItems({
      ...modalItems,
      desc: params.desc,
      name: params.name,
      price: params.price,
      category: params.category,
      id: params.id,
    });
  };

  const usersColumn = [
    {
      name: "Image",
      selector: (row: any) => {
        return <img src={row.file} alt="" className="w-14 py-4 rounded-full" />;
      },
    },
    { name: "Name", selector: (row: any) => row.name },
    { name: "Description", selector: (row: any) => row.desc },
    { name: "Price", selector: (row: any) => row.price },
    { name: "Category", selector: (row: any) => row.category },
    {
      name: "Action",
      selector: (row: any) => {
        return (
          <div className="flex-gap text-sm gap-3">
            <Pencil
              className="text-green-500 cursor-pointer w-4"
              onClick={() =>
                updateModalItems({
                  name: row.name,
                  desc: row.desc,
                  price: row.price,
                  category: row.category,
                  id: row.id,
                })
              }
            />
            <Trash
              className="text-red-500 cursor-pointer w-4"
              onClick={() => {
                setshow(true);
                setIds(row.id);
              }}
              // onClick={() => deleteDocument(row.id)}
            />
          </div>
        );
      },
    },
  ];

  // console.log(usersColumn);
  return (
    <div className="flex-center">
      {showModal && (
        <EditGadgets closeModal={closeModal} modalItems={modalItems} />
      )}
      {show && <DeleteModal closeModal1={closeModal1} ids={ids} />}
      <div className="w-full md:w-[650px] lg:w-[800px] px-4 md:rounded-xl pb-10 mt-20 md:shadow-xl md:border">
        <p className="font-semibold mt-4 text-2xl">Gadgets</p>
        <div className="mt-5 border rounded-lg">
          <DataTable data={documents} columns={usersColumn} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
