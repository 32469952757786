import { AtSign, Lock } from "lucide-react";
import React, { useState } from "react";
import CustomButton from "../components/CustomButton";
import { useLogin } from "../hooks/useLogin";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isPending } = useLogin();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <div className="h-[100vh] overflow-hidden w-full login-bg">
      <div className="w-full fixed z-10 left-0 top-0">
        <img
          src="/images/login-2.jpg"
          className="h-screen lg:h-auto w-auto"
          alt=""
        />
      </div>
      <div className="relative left-0 top-0 w-full h-full flex-center z-50">
        <div className="w-[500px] text-white rounded-xl shadow px-6 py-16 h-screen lg:h-auto bg-black/20 backdrop-blur-lg lg:backdrop-blur">
          <div className="flex-between items-center">
            <p className="text-4xl font-semibold">Welcome Back</p>
            <div className="w-16 h-16 rounded-full mt-5 bg-base-color"></div>
          </div>
          <p className="mt-2">Please login</p>
          <form className="mt-10" onSubmit={handleSubmit}>
            <div className="relative group">
              <AtSign className="absolute right-2 top-3" />
              <input
                type="email"
                className="py-3 w-full pl outline-none border-b bg-transparent"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative mt-8 group">
              <Lock className="absolute right-2 top-3" />
              <input
                type="password"
                className="py-3 w-full pl outline-none border-b bg-transparent"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex-between mt-4 text-sm">
              <p>Remember Me</p>
              <p>Forgot Password</p>
            </div>
            <div className="mt-12">
              <CustomButton className="py-3 lg:py-4 w-full text-white " />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
