import { CheckCheck, X } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import { useFirestore } from "../../../hooks/useFirestore";
import { toast } from "react-toastify";

const EditGadgets = ({ closeModal, modalItems }: any) => {
  const [desc, setDesc] = useState<string | null>(modalItems.desc);
  const [name, setName] = useState<string | null>(modalItems.name);
  const [category, setCategory] = useState<string | null>(modalItems.category);
  const [price, setPrice] = useState<string | null>(modalItems.price);
  const { updateDocument } = useFirestore("gadget");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await updateDocument(modalItems.id, { category, desc, name, price });
      // toast.success("Updated Successfully");
      closeModal();
    } catch (error) {
      // toast.error("Error Submitting");
    }
  };
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
        <div
          className="absolute w-full h-full bg-black opacity-50"
          onClick={closeModal}
        ></div>
        <div className="modal-container relative z-10 bg-white rounded-lg p-4">
          <div
            className="absolute top-0 right-0 mt-3 mr-3 text-xl cursor-pointer"
            onClick={closeModal}
          >
            <X />
          </div>
          <p className="text-2xl font-semibold">Edit Gadget</p>
          <form className="mt-10" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 gap-5">
              <div className="">
                <p className="font-semibold">Name:</p>
                <input
                  defaultValue={modalItems?.name}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  className="py-2 rounded-md mt-3 trans-props pl-3 w-full outline-none border focus:border-base-color"
                />
              </div>
              <div className="">
                <p className="font-semibold">Description:</p>
                <input
                  type="text"
                  defaultValue={modalItems?.desc}
                  onChange={(e) => setDesc(e.target.value)}
                  className="py-2 rounded-md trans-props pl-3 mt-3 w-full outline-none border focus:border-base-color"
                />
              </div>
              <div className="">
                <p className="font-semibold">Price:</p>
                <input
                  defaultValue={modalItems?.price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  className="py-2 rounded-md pl-3 trans-props mt-3 w-full outline-none border focus:border-base-color"
                />
              </div>
              <div className="">
                <p className="font-semibold">Category:</p>
                <select
                  name="Category"
                  onChange={(e) => setCategory(e.target.value)}
                  className="py-2 rounded-md pl-3 trans-props mt-3 w-full outline-none border focus:border-base-color"
                  defaultValue={modalItems.category}
                >
                  <option value="none" selected disabled hidden>
                    Select an Option
                  </option>
                  <option value="smart">Smart Devices</option>
                  <option value="home">Home Electronics</option>
                  <option value="gaming">Gaming Gear</option>
                </select>
              </div>
            </div>
            <div className="flex mt-5 justify-end">
              <CustomButton className="py-3 w-full lg:w-40 text-white" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditGadgets;
