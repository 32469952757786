import React from "react";

const HeroPage = () => {
  return (
    // <div className="lg:h-[80vh] w-full max-w padding-x relative bg-base-color">
    <div className="lg:h-[80vh] w-full max-w padding-x relative bg-[#000000]">
      <img
        src="/images/hero-bg-1.png"
        alt="icon"
        className="opacity-20 absolute w-1/2 lg:w-auto right-0 top-0"
      />
      <div className="w-10 bg-[#D4C431] h-10 absolute right-[45%] top-16 rounded-full"></div>
      <div className="w-6 bg-[#D27022] h-6 absolute right-[15%] top-16 rounded-full"></div>
      <div className="w-7 bg-[#3FA4A4] h-7 absolute left-[15%] bottom-16 rounded-full"></div>
      <div className="w-full h-full flex flex-col-reverse gap-16 lg:gap-0 lg:flex-row lg:justify-between pb-14 lg:pb-0 pt-28 lg:pt-0 items-center">
        <div className="relative">
          <div className="absolute w-12 h-12 border-2  -left-16 -top-5 rounded-full bg-white">
            <img src="/logo.png" alt="logo" />
          </div>
          <p className="text-font text-white text-xl font-semibold">
            In this season, find the best 🔥
          </p>
          <p className="text-font text-4xl lg:text-5xl xl:text-6xl w-full mt-3 lg:w-[70%] text-white font-bold">
            Exclusive collection for everyone
          </p>
          <p className="text-font mt-5 text-white">
            Discover more. Good things are waiting for you
          </p>
          <button className="bg-white hover:bg-opacity-80 flex-center gap-2 overflow-hidden rounded trans-props group w-full md:w-40 py-3 mt-7">
            Explore Now
            <div className="overflow-hidden relative">
              <img
                src="/icons/arrow-right.png"
                className="group-hover:translate-x-11 trans-props"
                alt=""
              />
              <img
                src="/icons/arrow-right.png"
                className="group-hover:translate-x-0 absolute top-0 -translate-x-10 trans-props"
                alt=""
              />
            </div>
          </button>
        </div>
        <div className="relative z-10">
          <img src="/images/slider-img-1.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
