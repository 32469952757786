import { customButtonProps } from "../types";

const CustomButton = ({
  title,
  className,
  bgColor,
  handleEvent,
  disabled,
  loading,
  type,
}: customButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={handleEvent}
      type={type}
      className={`${className} ${
        bgColor ? bgColor : "bg-base-color"
      } md:px-4 hover:opacity-75 font-bold disabled:opacity-60 text-sm rounded transition duration-500`}
    >
      {loading ? (
        <div
          aria-label="Loading..."
          role="status"
          className="flex justify-center items-center space-x-2"
        >
          <span className="text-[12px] font-bold text-white">
            Please Wait...
          </span>
          {/* <Loader className="animate-spin" /> */}
        </div>
      ) : (
        `${title ? title : "Submit"}`
      )}
    </button>
  );
};

export default CustomButton;
