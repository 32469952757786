export const heroWelCard = [
  {
    title: "Gaming Gear",
    img: "/images/hero-3.png",
    text: "Save 45% Off",
    color: "",
    bgColor: "#49B533",
    btnColor: "bg-[#FFC000]",
  },
  {
    title: "Smart Devices",
    text: "Exclusive Here!",
    img: "/images/hero-2.png",
    color: "",
    bgColor: "#115061",
    btnColor: "bg-[#49B332]",
  },
  {
    title: "Home Electronics",
    text: "Up To 20% Offers",
    img: "/images/hero-1.png",
    color: "",
    bgColor: "#585B62",
    btnColor: "bg-black",
  },
];

export const heroItems = [
  {
    name: "Earmax TWS Earphone",
    oldPrice: 20000,
    newPrice: 15000,
    description: "Description Here",
    img: "/images/airpod-1.jpg",
  },
  {
    name: "Earmax TWS Earphone",
    oldPrice: 20000,
    newPrice: 15000,
    description: "Description Here",
    img: "/images/bluethoot-1.webp",
  },
  {
    name: "Earmax TWS Earphone",
    oldPrice: 20000,
    newPrice: 15000,
    description: "Description Here",
    img: "/images/airpod-2-300x300.jpg",
  },
  {
    name: "Earmax TWS Earphone",
    oldPrice: 20000,
    newPrice: 15000,
    description: "Description Here",
    img: "/images/hero-1.png",
  },
  {
    name: "Earmax TWS Earphone",
    oldPrice: 20000,
    newPrice: 15000,
    description: "Description Here",
    img: "/images/slider-img-1.png",
  },
];
