import React from "react";
import PageWrapper from "../../../components/PageWrapper";
import { motion } from "framer-motion";
import { heroWelCard } from "../constants";
import CustomButton from "../../../components/CustomButton";

const HeroWelCard = () => {
  return (
    <PageWrapper title="Category">
      <motion.div className="grid overflow-hidden md:grid-cols-2 text-white lg:grid-cols-3 gap-10">
        {heroWelCard.map((item, index) => {
          return (
            <motion.div
              transition={{
                ease: "easeInOut",
                duration: 0.8,
                delay: 0.2 * 0.2 + index,
              }}
              viewport={{ once: true }}
              initial={{
                opacity: 0,
              }}
              whileInView={{
                translateX: ["120%", "0%"],
                opacity: 1,
              }}
              className={`py-5 overflow-hidden cursor-pointer trans-props hover:-translate-x-5 hover:shadow-xl group flex-between border rounded-xl p-6`}
              style={{
                backgroundColor: item.bgColor,
              }}
              key={index}
            >
              <div className="">
                <p className="font-semibold text-2xl">{item.title}</p>
                <p className="mt-3 text-lg">{item.text}</p>
                <div className="mt-5">
                  <CustomButton
                    title="Shop Now"
                    className="py-3 px-7 hover:bg-transparent hover:border hover:border-white trans-props rounded-full"
                    bgColor={item.btnColor}
                  />
                </div>
              </div>
              <img
                className="relative group-hover:scale-110 trans-props w-[60%] top-0 right-0"
                src={item.img}
                alt=""
              />
            </motion.div>
          );
        })}
      </motion.div>
    </PageWrapper>
  );
};

export default HeroWelCard;
