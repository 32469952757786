import React from "react";
import HeroPage from "./components/HeroPage";
import HeroWelCard from "./components/HeroWelCard";
import HeroCategory from "./components/HeroCategory";
import HeroBadge from "./components/HeroBadge";

const Home = () => {
  return (
    <div>
      <HeroPage />
      <HeroWelCard />
      <HeroCategory />
      <HeroBadge />
    </div>
  );
};

export default Home;
